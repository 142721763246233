<template>
  <header class="header" @mouseleave="closeList">
    <div class="header__bg"></div>
    <div class="container">
      <div class="header__wrapper">
        <router-link @mouseenter.native="closeList"
                     class="header__logo"
                     active-class=""
                     exact-active-class=""
                     :to="{name: 'home'}">
          <img src="@/img/logo.svg" alt="">
        </router-link>
        <nav ref="nav" class="header__nav">
          <ul>
            <li>
              <div @mouseenter="openList" ref="dropdownButton"
                   @click.self="$router.push({ name: 'allTournaments'})"
                   :class="[{active: tournamentsListVisible}, 'header-dropdown-button']">
                турниры
                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 6.5L8 10.5L4 6.5" stroke="#EAEAEA" stroke-linejoin="round"/>
                </svg>
              </div>
            </li>
            <li @mouseenter="closeList" v-for="link in navItems.slice(2)" :key="link.path">
              <router-link :to="{name: link.path}"
                           class="header__link"
                           exact-active-class="active">{{ link.label }}
              </router-link>
            </li>
            <li>
              <a class="header__link"
                 target="_blank"
                 href="https://tinyurl.com/25jvtytc">Winline</a>
            </li>
          </ul>
        </nav>
        <!--        <div class="header-partners">
                  <div class="partners-logo">
                    <img :src="require('@/img/partners-logo/logo1.png')" alt="#">
                  </div>
                  <div class="partners-logo">
                    <img :src="require('@/img/partners-logo/logo2.png')" alt="#">
                  </div>
                  <div class="partners-logo">
                    <img :src="require('@/img/partners-logo/logo3.png')" alt="#">
                  </div>
                  <div class="partners-logo">
                    <img :src="require('@/img/partners-logo/logo4.png')" alt="#">
                  </div>
                </div>-->
        <div class="profile-area">
          <search-input/>

          <header-profile v-if="AUTH.isAuthenticate"></header-profile>
          <div class="login-buttons" v-else>
            <button class="filled__yellow" @click.stop="showUserAuthModals">Войти</button>
          </div>
        </div>
        <media :query="{maxWidth: 1100}">
          <div class="more" @click="toggleStatusMobileNav">
            <div class="more_btn more_btn__mobile" :class="{active: this.GET_STATUS_MOBILE_NAV}">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </media>
      </div>
    </div>
    <div ref="disciplinesList" class="header-tournaments-list">
      <div class="list-wrapper" v-if="dataReady">
        <router-link :to="{name: 'sgl-events', params: {id: game.id}}" class="discipline-logo"
                     v-for="game in this.GET_TOURNAMENT_GROUPS" :key="game.id"
                     active-class=""
                     exact-active-class="">
          <img :src="GET_SPONSOR_GAMES.find(el => String(el.id) === String(game.gameInfo.id)).logo" alt="#">
        </router-link>
      </div>
    </div>

    <media :query="{maxWidth: 1100}">
      <div class="mobile_nav" :class="{active: this.GET_STATUS_MOBILE_NAV}">
        <div class="container">
          <search-input/>
          <nav>
            <ul>
              <li @click="closeMobileNav" v-for="link in navItems" :key="link.path">
                <router-link :to="{name: link.path}" class="header__link" exact-active-class="active">{{
                    link.label
                  }}
                </router-link>
              </li>
            </ul>
          </nav>
          <media :query="{maxWidth: 580}">
            <div class="divider" v-if="AUTH.isAuthenticate"></div>
          </media>
          <media :query="{maxWidth: 580}">
            <nav v-if="AUTH.isAuthenticate">
              <ul>
                <li @click="closeMobileNav">
                  <router-link :to="{name: 'user', params: {id: USER.id}}" class="header__link"
                               exact-active-class="active">Профиль
                  </router-link>
                </li>
                <!--                <li @click="closeMobileNav">-->
                <!--                  <router-link to="/teams/create" class="header__link" exact-active-class="active">Создать команду-->
                <!--                  </router-link>-->
                <!--                </li>-->
                <li @click="closeMobileNav">
                  <div class="header__link" @click="LOGG_OUT">Выйти из аккаунта</div>
                </li>
              </ul>
            </nav>
          </media>
        </div>
      </div>
    </media>

    <login-modal
        v-if="AUTH.isAuthOpen"
    />
  </header>
</template>

<script>
import searchInput from "@/components/UIElements/InputSearch";
import HeaderProfile from "@/components/UIElements/headerProfile";
import Media from 'vue-media';
import LoginModal from "@/components/modals/login-modal";
import {mapGetters, mapActions} from 'vuex';

export default {
  name: "headerMain",
  components: {
    HeaderProfile,
    searchInput,
    Media,
    LoginModal
  },
  data() {
    return {
      tournamentsListVisible: false,
      dataReady: false,
      navItems: [
        {path: 'home', label: 'Главная'},
        {path: 'allTournaments', label: 'Турниры'},
        // {path: 'news', label: 'Новости'},
        {path: 'videos', label: 'Видео'},
        // {path: 'FAQ', label: 'FAQ'},
      ]
    };
  },
  computed: {
    ...mapGetters([
      'AUTH',
      'GET_STATUS_MOBILE_NAV',
      'GET_TOURNAMENT_GROUPS',
      'GET_SPONSOR_GAMES'
    ]),
  },
  methods: {
    ...mapActions([
      'SHOW_USER_AUTH_MODALS',
      'USER',
      'GET_SINGLE_USER',
      'LOGG_OUT',
      'SET_STATUS_MOBILE_NAV',
      'TOGGLE_STATUS_MOBILE_NAV',
      'SET_INACTIVE_MODAL_NOTIFICATIONS',
      'GET_DISCIPLINES',
      'SET_TOURNAMENT_GROUPS',
      'GET_SPONSOR_DISCIPLINES',
      'CLEAR_REGISTER_AFTER_AUTH_ACTION'
    ]),
    showUserAuthModals() {
      this.CLEAR_REGISTER_AFTER_AUTH_ACTION()

      // Показываем модалку с регистрацией
      this.SHOW_USER_AUTH_MODALS({isAuth: true, modal: 'password-auth'})
    },
    closeMobileNav() {
      this.SET_STATUS_MOBILE_NAV(false)
    },
    toggleStatusMobileNav() {
      this.SET_INACTIVE_MODAL_NOTIFICATIONS()
      this.TOGGLE_STATUS_MOBILE_NAV()
    },
    setLeftMargin() {
      this.$refs.disciplinesList.style.marginLeft = `${this.$refs.nav.getBoundingClientRect().left}px`
    },
    autoMargin() {
      window.addEventListener('resize', () => this.setLeftMargin())
    },
    openList() {
      this.$refs.disciplinesList.style.maxHeight = `${this.$refs.disciplinesList.scrollHeight}px`
      this.tournamentsListVisible = true
    },
    closeList() {
      this.$refs.disciplinesList.style.maxHeight = `0px`
      this.tournamentsListVisible = false
    },
    async getData() {
      //TODO надо будет убрать отсюда, потому что в App.vue мы их уже получаем. Сделать прелоадер в шапке, пока они грузятся
      await this.GET_SPONSOR_DISCIPLINES()
      await this.SET_TOURNAMENT_GROUPS()
      this.dataReady = true
    }
  },
  watch: {
    GET_STATUS_MOBILE_NAV: function () {
      // document.documentElement.style.overflow = this.GET_STATUS_MOBILE_NAV ? 'hidden' : 'auto';

      if (this.GET_STATUS_MOBILE_NAV) {
        document.body.classList.add('body_disableOverflow');
      } else {
        document.body.classList.remove('body_disableOverflow');
      }
    }
  },
  mounted() {
    this.getData()
    this.setLeftMargin()
    this.autoMargin()
    /*let showOrHideNav = new HideNavToMore('.header__nav .more', '.header__nav', '.header__nav .hidden__els', '.header__nav li', 1)
    showOrHideNav.start();*/
  }
}
</script>

<style scoped>
.filled__yellow {
  width: 200px;
  margin-right: 16px;
}
</style>
